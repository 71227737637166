import React from 'react';
// import { graphql } from 'react-apollo';
// import gql from 'graphql-tag';
import { graphql } from 'gatsby';
import { StaticQuery } from "gatsby"

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import ObjectCard from '../components/ObjectCard';
import Layout from '../components/Layout';
import SEO from '../components/SEO';


const CARDS_PER_PAGE = 6;

const People = () => (
  <StaticQuery
    query={query}
    render={data => (
      <Layout>

        <SEO 
          title="People"
          description="People who still haunt this world."
        />

        <Typography variant="h2">
          People
        </Typography>
        <section>
          <Grid container spacing={2}>
            {data.gcms.people.map(person => (
              <ObjectCard
                key={person.id}
                title={person.name}
                link={`/person/${person.slug}`}
                image={person.gallery[0]}
                relatedPlaceCount={person.places.length}
                relatedBookCount={person.books.length}
                relatedInvestigationCount={person.investigations.length}
                relatedTourCompanyCount={person.tourCompanies.length}
                relatedPersonCount={0}
                relatedLoreCount={person.lores.length}
              />
            ))}
          </Grid>

        </section>
      </Layout>
    )}
  />
);

const query = graphql`
  query {
    gcms {
      people(orderBy: publishedAt_DESC) {
        id
        name
        slug
        gallery (first: 1) {
          handle
          title
        }
        books {id}
        investigations {id}
        tourCompanies {id}
        places {id}
        lores {id}
      }
      peopleConnection {
        aggregate {
          count
        }
      }
    }
  }
`;


export default People;

// const People = ({
//   data: { loading, error, people, peopleConnection, networkStatus },
//   loadMoreCards,
// }) => {
//   if (error) return <h1>Error fetching people!</h1>;
//   if (people && peopleConnection) {
//     const areMorePosts = people.length < peopleConnection.aggregate.count;
//     return (
//       <React.Fragment>
//         <Typography variant="h2">
//           People
//         </Typography>
//         <section>
//             <Grid container spacing={2}>
//                 {people.map(person => (
//                   <ObjectCard 
//                     title={person.name}
//                     link={`/person/${person.slug}`}
//                     image={person.gallery[0]}
//                     relatedPlaceCount = {person.places.length}
//                     relatedBookCount = {person.books.length}
//                     relatedInvestigationCount = {person.investigations.length}
//                     relatedTourCompanyCount = {person.tourCompanies.length}
//                     relatedPersonCount = {0}
//                     relatedLoreCount = {person.lores.length}
//                   />
//                 ))}
//             </Grid>

//             <Grid container justify="center" style={{marginTop: '16px'}}>
//               <Grid item >
//                 {areMorePosts ? (
//                   <Button 
//                     variant="contained" 
//                     color="primary"
//                     disabled={loading}
//                     onClick={() => loadMoreCards()}
//                   >
//                     {loading ? 'Loading...' : 'Show More People'}
//                   </Button>
//                 ) : (
//                   ''
//                 )}
//               </Grid>
//             </Grid>
//         </section>
//       </React.Fragment>
//     );
//   }
//   return <h2>Loading people...</h2>;
// };

// export const query = gql`
// query people($first: Int!, $skip: Int!) {
//   people(orderBy: publishedAt_DESC, first: $first, skip: $skip) {
//     id
//     name
//     slug
//     gallery (first: 1) {
//       handle
//       title
//     }
//     books {id}
//     investigations {id}
//     tourCompanies {id}
//     places {id}
//     lores {id}
//   }
//   peopleConnection {
//     aggregate {
//       count
//     }
//   }
// }
// `;

// export const queryVars = {
// skip: 0,
// first: CARDS_PER_PAGE,
// };

// export default graphql(query, {
// options: {
//   variables: queryVars,
// },
// props: ({ data }) => ({
//   data,
//   loadMoreCards: () => {
//     return data.fetchMore({
//       variables: {
//         skip: data.people.length,
//       },
//       updateQuery: (previousResult, { fetchMoreResult }) => {
//         if (!fetchMoreResult) {
//           return previousResult;
//         }
//         return Object.assign({}, previousResult, {
//           people: [...previousResult.people, ...fetchMoreResult.people],
//         });
//       },
//     });
//   },
// }),
// })(People);